<template>
  <div>
    <loading v-if="loading" />

    <div v-else-if="error">
      <no-results
        :cta="{ label: 'Try again', class: 'is-warning' }"
        icon="exclamation-triangle"
        title="Oops, something went wrong!"
        message="There was a problem retrieving database backups for this site; please try again and let us know if the problem persists."
        @click="getDatabaseConfig"
      />
    </div>

    <div v-else-if="!hasConfig">
      <no-results
        :cta="{ label: 'Configure now', class: 'is-success' }"
        icon="cog"
        title="Not configured"
        message="Database backups have not been configured."
        @click="configureDatabaseBackup()"
      />
    </div>

    <div v-else>
      <b-table :data="config.message" :loading="processing">
        <template slot-scope="props">
          <b-table-column field="id" label="Status">
            <status-label
              :status="
                props.row.markedfordeletion
                  ? 'backup-deleted'
                  : props.row.backupstatus
              "
            />
          </b-table-column>

          <b-table-column field="id" label="Database">{{
            props.row.name
          }}</b-table-column>

          <b-table-column field="host" label="Host">{{
            `${props.row.host}:${props.row.port}`
          }}</b-table-column>

          <b-table-column field="lastbackup" label="Last Backup" width="160">
            <span v-if="props.row.lastbackup">{{
              $moment(new Date(props.row.lastbackup)).from()
            }}</span>
            <span v-else>–</span>
          </b-table-column>

          <b-table-column label="Snapshots" width="140">
            <a
              v-if="props.row.backups.length"
              class="has-text-dark"
              @click="viewDatabaseSnapshots(props.row.name, props.row.backups)"
            >
              <u>{{ props.row.backups.length }} snapshot(s)</u>
            </a>
            <span v-else>-</span>
          </b-table-column>

          <b-table-column label width="1" class="has-text-right">
            <b-dropdown position="is-bottom-left" class="has-text-left">
              <a slot="trigger" class="has-text-dark">
                <b-icon icon="cog" />
              </a>

              <b-dropdown-item
                v-if="
                  !props.row.markedfordeletion &&
                    !props.row.deleteforgdpr &&
                    !['inprogress', 'none'].includes(props.row.backupstatus) &&
                    props.row.backupnow === 0
                "
                @click="backupNow(props.row)"
                >Backup now</b-dropdown-item
              >

              <b-dropdown-item
                v-if="allowEdit(props.row)"
                @click="configureDatabaseBackup(props.row)"
                >Edit</b-dropdown-item
              >

              <b-dropdown-item
                v-if="!props.row.markedfordeletion && !props.row.deleteforgdpr"
                class="has-text-danger"
                @click="deleteDatabaseConfig(props.row)"
                >Delete</b-dropdown-item
              >

              <b-dropdown-item
                v-if="props.row.markedfordeletion || props.row.deleteforgdpr"
                @click="undeleteDatabaseConfig(props.row)"
                >Undelete</b-dropdown-item
              >
            </b-dropdown>
          </b-table-column>
        </template>
      </b-table>

      <button
        type="button"
        class="button has-margin-top-100"
        @click="configureDatabaseBackup()"
      >
        Add database
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatabaseBackups",
  props: {
    siteId: {
      type: String,
      required: true
    },
    headerless: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      error: false,
      config: {}
    };
  },
  computed: {
    hasConfig() {
      return this.$_.isArray(this.config.message);
    },
    isAgent() {
      return this.$store.getters["user/isAgent"]();
    }
  },
  created() {
    this.getDatabaseConfig();
  },
  methods: {
    allowEdit(row) {
      if (this.isAgent) return true;
      return (
        !row.markedfordeletion &&
        !row.deleteforgdpr &&
        !["inprogress", "none"].includes(row.backupstatus) &&
        row.backupnow === 0
      );
    },
    getDatabaseConfig() {
      this.processing = true;
      this.error = false;
      this.$store
        .dispatch("sites/backups/api", {
          type: "get-db-config",
          siteId: this.siteId
        })
        .then(response => {
          this.$set(this, "config", response);
        })
        .catch(() => {
          this.$set(this, "error", true);
          this.$toast.open({
            message: "Error retrieving backup config",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
          this.loading = false;
        });
    },
    configureDatabaseBackup(config = {}) {
      const modal = this.$modal.open({
        component: () => import("@shared/backups/_dbConfigModal"),
        parent: this,
        width: 640,
        hasModalCard: true,
        canCancel: ["outside", "escape"],
        props: {
          siteId: this.siteId,
          config: config
        },
        events: {
          refresh: () => {
            this.getDatabaseConfig();
            modal.close();
          }
        }
      });
    },
    backupNow(config) {
      this.processing = true;
      this.$store
        .dispatch("sites/backups/api", {
          type: "update-db-config",
          siteId: this.siteId,
          payload: {
            name: config.name,
            backupnow: 1
          }
        })
        .then(() => {
          this.$toast.open({
            message: "Backup requested",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "There was a problem requesting a backup",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
          this.getDatabaseConfig();
        });
    },
    undeleteDatabaseConfig(config) {
      this.$store
        .dispatch("sites/backups/api", {
          type: "update-db-config",
          siteId: this.siteId,
          payload: this.$_.merge(
            {},
            {},
            {
              name: config.name,
              markedfordeletion: 0
            }
          )
        })
        .then(result => {
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            queue: false
          });
          this.getDatabaseConfig();
        })
        .catch(() => {
          this.getDatabaseConfig();
          this.processing = false;
          this.$toast.open({
            message: "There was a problem undeleting your configuration",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    },
    deleteDatabaseConfig(config) {
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Delete",
          message: `Are you sure you want to delete this backup configuration ?`
        },
        canCancel: [],
        events: {
          confirmed: async () => {
            await this.onDeleteConfirmed(config);
            confirm.close();
          }
        }
      });
    },
    onDeleteConfirmed(config) {
      return this.$store
        .dispatch("sites/backups/api", {
          type: "delete-db-config",
          siteId: this.siteId,
          payload: {
            name: config.name
          }
        })
        .then(result => {
          this.getDatabaseConfig();
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: "There was a problem deleting your configuration",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    },
    viewDatabaseSnapshots(database, backups = []) {
      this.$modal.open({
        component: () => import("@shared/backups/_dbSnapshotsModal"),
        parent: this,
        width: 840,
        hasModalCard: true,
        canCancel: ["outside"],
        props: {
          siteId: this.siteId,
          database,
          backups
        }
      });
    }
  }
};
</script>
