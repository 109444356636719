var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('loading'):(_vm.error)?_c('div',[_c('no-results',{attrs:{"cta":{ label: 'Try again', class: 'is-warning' },"icon":"exclamation-triangle","title":"Oops, something went wrong!","message":"There was a problem retrieving database backups for this site; please try again and let us know if the problem persists."},on:{"click":_vm.getDatabaseConfig}})],1):(!_vm.hasConfig)?_c('div',[_c('no-results',{attrs:{"cta":{ label: 'Configure now', class: 'is-success' },"icon":"cog","title":"Not configured","message":"Database backups have not been configured."},on:{"click":function($event){return _vm.configureDatabaseBackup()}}})],1):_c('div',[_c('b-table',{attrs:{"data":_vm.config.message,"loading":_vm.processing},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"field":"id","label":"Status"}},[_c('status-label',{attrs:{"status":props.row.markedfordeletion
                ? 'backup-deleted'
                : props.row.backupstatus}})],1),_c('b-table-column',{attrs:{"field":"id","label":"Database"}},[_vm._v(_vm._s(props.row.name))]),_c('b-table-column',{attrs:{"field":"host","label":"Host"}},[_vm._v(_vm._s(`${props.row.host}:${props.row.port}`))]),_c('b-table-column',{attrs:{"field":"lastbackup","label":"Last Backup","width":"160"}},[(props.row.lastbackup)?_c('span',[_vm._v(_vm._s(_vm.$moment(new Date(props.row.lastbackup)).from()))]):_c('span',[_vm._v("–")])]),_c('b-table-column',{attrs:{"label":"Snapshots","width":"140"}},[(props.row.backups.length)?_c('a',{staticClass:"has-text-dark",on:{"click":function($event){return _vm.viewDatabaseSnapshots(props.row.name, props.row.backups)}}},[_c('u',[_vm._v(_vm._s(props.row.backups.length)+" snapshot(s)")])]):_c('span',[_vm._v("-")])]),_c('b-table-column',{staticClass:"has-text-right",attrs:{"label":"","width":"1"}},[_c('b-dropdown',{staticClass:"has-text-left",attrs:{"position":"is-bottom-left"}},[_c('a',{staticClass:"has-text-dark",attrs:{"slot":"trigger"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"cog"}})],1),(
                !props.row.markedfordeletion &&
                  !props.row.deleteforgdpr &&
                  !['inprogress', 'none'].includes(props.row.backupstatus) &&
                  props.row.backupnow === 0
              )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.backupNow(props.row)}}},[_vm._v("Backup now")]):_vm._e(),(_vm.allowEdit(props.row))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.configureDatabaseBackup(props.row)}}},[_vm._v("Edit")]):_vm._e(),(!props.row.markedfordeletion && !props.row.deleteforgdpr)?_c('b-dropdown-item',{staticClass:"has-text-danger",on:{"click":function($event){return _vm.deleteDatabaseConfig(props.row)}}},[_vm._v("Delete")]):_vm._e(),(props.row.markedfordeletion || props.row.deleteforgdpr)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.undeleteDatabaseConfig(props.row)}}},[_vm._v("Undelete")]):_vm._e()],1)],1)]}}])}),_c('button',{staticClass:"button has-margin-top-100",attrs:{"type":"button"},on:{"click":function($event){return _vm.configureDatabaseBackup()}}},[_vm._v(" Add database ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }