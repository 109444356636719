import { render, staticRenderFns } from "./_dbBackupsCard.vue?vue&type=template&id=6b8c85e7"
import script from "./_dbBackupsCard.vue?vue&type=script&lang=js"
export * from "./_dbBackupsCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports